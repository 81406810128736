import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CosmicService } from 'src/app/services/cosmic.service';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { fadeIn } from 'src/app/animations/animations';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss'],
  animations: [fadeIn]
})
export class DetailPageComponent implements OnInit {
    public item;
    public faArrowLeft = faArrowLeft;
    public faArrowRight = faArrowRight;
    
    constructor(private router: ActivatedRoute, private route: Router, private cosmicService: CosmicService) { } 
    
    public ngOnInit() {
        this.router.queryParams.subscribe(data => {
            this.getItem(data);
        });
        
    }
   
    private getItem(data: any) {
        const slug = data.slug;
        this.cosmicService.getSingleCaseStudy().subscribe(res => {
            data = res;
            this.item = data.objects.filter(item => item.slug === slug)[0];
            window.scroll({
                top: 0
            });
        });
    }

}
