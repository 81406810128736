import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';
import { StickyHeaderService } from 'src/app/services/sticky-header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
    public faHome = faHome;
    public faLinkedinIn = faLinkedinIn;
    public faGithub = faGithub;

    @Input() public shouldStick: boolean = false;

    constructor(public router: Router, private stickyHeaderService: StickyHeaderService) { }

    @ViewChild('header', {static: true}) private header: ElementRef;

    public ngAfterViewInit() {
        this.stickyHeaderService.headerHeight = this.header.nativeElement.offsetHeight;
    }

}
