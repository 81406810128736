import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {config} from '../../config/cosmo.config'
import { map } from 'rxjs/operators';


@Injectable()

export class CosmicService {
    private URL = config.URL;
    private bucket_slug = config.bucket_slug;
    
    constructor(private _http: HttpClient) {}

    public getCaseStudies() {
        return this._http.get(this.URL + this.bucket_slug + "/object-type/case-studies", {
                params: {
                    read_key: config.read_key,
                }
            })
            .pipe(map(res => {
                return res;
            }));
    }

    public getSingleCaseStudy() {
        return this._http.get(this.URL + this.bucket_slug + "/object-type/case-studies/", {
            params: {
                read_key: config.read_key
            }
        })
    }

    public getLinkedItems() {
        return this._http.get(this.URL + this.bucket_slug + "/object-type/linked-items", {
                params: {
                    read_key: config.read_key,
                }
            })
            .pipe(map(res => {
                return res;
            }));
    }

}