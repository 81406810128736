import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/page/home/home.component';
import { DetailPageComponent } from './components/page/detail-page/detail-page.component';


const routes: Routes = [
    {path: '', component: HomeComponent, data: { animation: 'HomePage' }},
    {path: 'detail-page', component: DetailPageComponent, data: { animation: 'PortfolioPage' }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
