// angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

// vendor
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// app
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/global/header/header.component';
import { FooterComponent } from './components/global/footer/footer.component';
import { HomeComponent } from './components/page/home/home.component';
import { AudioBtnComponent } from './components/page/home/subcomponents/audio-btn/audio-btn.component';
import { DetailPageComponent } from './components/page/detail-page/detail-page.component';
import { LinkedItemComponent } from './components/page/home/subcomponents/linked-item/linked-item.component';
import { ExternalLinksComponent } from './components/page/home/subcomponents/external-links/external-links.component';
import { CaseStudyListComponent } from './components/page/home/subcomponents/case-study-list/case-study-list.component';
import { CaseStudyComponent } from './components/page/home/subcomponents/case-study/case-study.component';
import { AppearDirective } from './directives/appear.directive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AudioBtnComponent,
    DetailPageComponent,
    LinkedItemComponent,
    ExternalLinksComponent,
    CaseStudyListComponent,
    CaseStudyComponent,
    AppearDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModalModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
