import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public currentDate = new Date();
    public faArrowCircleUp = faArrowCircleUp;

    constructor(public viewportScroller: ViewportScroller) { }

    ngOnInit() {
    }

    public onBtnClick(): void {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

}
