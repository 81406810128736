import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routerTransition, fadeIn } from './animations/animations';
import { CosmicService } from './services/cosmic.service';
import { StickyHeaderService } from './services/sticky-header.service';
import { Subscription, fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerTransition, fadeIn],
    providers: [CosmicService, StickyHeaderService]
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'Shaina Koval\'s Portfolio';
    public shouldStick: boolean = false;
    private lastScrollTop: number = 0;

    constructor(public stickyHeaderService: StickyHeaderService) {}

    public prepareRoute(outlet: RouterOutlet) {
        if (outlet && outlet.activatedRouteData) return outlet.activatedRouteData['animation'];
    }

    public ngOnInit(): void {
        this.lastScrollTop = window.scrollY;
        this.subscribeToScroll();
    }

    public ngOnDestroy() {
        this.unsubscribeFromScroll();
    }

    private subscriptionScroll: Subscription;
    private subscribeToScroll() {
        this.subscriptionScroll = fromEvent(window, 'scroll').pipe(startWith(null))
            .subscribe(() => {
                const currentScrollTop: number = window.scrollY;
                if (window.scrollY > 0) {
                    if (window.scrollY > 0 && currentScrollTop < this.lastScrollTop) {
                        this.shouldStick = true;
                    } else {
                        this.shouldStick = false;
                    }
                } else {
                    this.shouldStick = false;
                }
                this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
            });
    }

    private unsubscribeFromScroll() {
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }
    }
}
