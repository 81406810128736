import { Component,  Input } from '@angular/core';
import { caseStudyModel } from 'src/app/models/cosmic.model';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent {
    @Input() data: caseStudyModel;
    @Input() number: string;

    public onAppear(el: HTMLElement): void {
        el.classList.add('fader--visible');
    }

}
