import {
    ElementRef, Output, Directive, AfterViewInit, OnDestroy, EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
    selector: '[appAppear]'
})
export class AppearDirective implements AfterViewInit, OnDestroy {
    @Output() public appear: EventEmitter<void>;

    private elementRect: any;

    private  subscriptionScroll: Subscription;
    private  subscriptionResize: Subscription;

    constructor(private element: ElementRef) {
        this.appear = new EventEmitter<void>();
        this.checkVisibility();
    }

    private checkVisibility() {
        if (this.isVisible()) {
            // double check dimensions (due to async loaded contents, e.g. images)
            if (this.isVisible()) {
                this.unsubscribe();
                this.appear.emit();
            }
        }
    }

    private isVisible() {
        this.elementRect = this.element.nativeElement.getBoundingClientRect();
        const visibilityOffset: number = 40;
        return (
            this.elementRect.top >= 0 &&
            this.elementRect.top + visibilityOffset <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    private subscribe() {
        this.subscriptionScroll = fromEvent(window, 'scroll').pipe(startWith(null))
            .subscribe(() => {
                this.checkVisibility();
            });
        this.subscriptionResize = fromEvent(window, 'resize').pipe(startWith(null))
            .subscribe(() => {
                this.checkVisibility();
            });
    }

    private unsubscribe() {
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }
        if (this.subscriptionResize) {
            this.subscriptionResize.unsubscribe();
        }
    }

    public ngAfterViewInit() {
        this.subscribe();
    }

    public ngOnDestroy() {
        this.unsubscribe();
    }
}