import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-audio-btn',
    templateUrl: './audio-btn.component.html',
    styleUrls: ['./audio-btn.component.scss']
})
export class AudioBtnComponent implements OnInit {

    constructor() { }

    public ngOnInit() {
    }

    @ViewChild('audio', {static: false}) public audioRef: ElementRef;

    public onClick(): void {
        this.audioRef.nativeElement.play();
    }

}
