import { Component, OnInit } from '@angular/core';
import { CosmicService } from 'src/app/services/cosmic.service';
import { caseStudyModel } from 'src/app/models/cosmic.model';

@Component({
  selector: 'app-case-study-list',
  templateUrl: './case-study-list.component.html',
  styleUrls: ['./case-study-list.component.scss']
})
export class CaseStudyListComponent implements OnInit {
    private caseStudyData: any;
    public caseStudies: caseStudyModel[];

    constructor(private cosmicService: CosmicService) { }

    public ngOnInit() {
        this.getCaseStudies();
    }

    private getCaseStudies() {
        this.cosmicService.getCaseStudies().subscribe(res => {
            this.caseStudyData = res;
            this.caseStudies = this.caseStudyData.objects;
        })
    }

}
