import { Component, OnInit } from '@angular/core';
import { CosmicService } from 'src/app/services/cosmic.service';

@Component({
  selector: 'app-external-links',
  templateUrl: './external-links.component.html',
  styleUrls: ['./external-links.component.scss']
})
export class ExternalLinksComponent implements OnInit {
    private linkedData: any;
    public linkedItems: any[];

    constructor(private cosmicService: CosmicService) { }

    public ngOnInit() {
        this.getLinkedItems();
    }

    private getLinkedItems() {
        this.cosmicService.getLinkedItems().subscribe(res => {
            this.linkedData = res;
            this.linkedItems = this.linkedData.objects;
        })
    }

}
