import { Component, OnInit, Input } from '@angular/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-linked-item',
  templateUrl: './linked-item.component.html',
  styleUrls: ['./linked-item.component.scss']
})
export class LinkedItemComponent implements OnInit {
    public faExternalLinkAlt = faExternalLinkAlt;

    @Input() public data: any;

    constructor() { }

    public ngOnInit() {
    }

    public onAppear(el: HTMLElement): void {
        el.classList.add('fader--visible');
    }

}
