import { trigger, transition, style, animate, state } from '@angular/animations';

export const routerTransition =
    trigger('routeAnimations', [
        transition('HomePage => PortfolioPage', [
            style({
				opacity: 0
			}),
            animate('0.2s', style({
				opacity: 1
			}))
        ]),
        transition('PortfolioPage => HomePage', [
            style({
				opacity: 0
			}),
            animate('0.2s', style({
				opacity: 1
			}))
        ])
    ]);

export const fadeIn = trigger('fadeIn', [
    state('void', 
        style({ opacity: 0 })
    ),
    state('*', 
        style({ opacity: 1 })
    ),
    transition('void <=> *', [
        animate('0.5s')
    ])
])
    
    